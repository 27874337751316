import appInfo from '../../../static/app_info'
import React from 'react'

const DepletionStationOptions = () => (
  Object.entries(appInfo.stations)
    .map(x =>
      <option key={'station-sel-' + x[0]} value={x[0]}>
        {x[1].name}
      </option>
    )
)

export default DepletionStationOptions