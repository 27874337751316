import appInfo from '../../../static/app_info'
import React from 'react'

const DepletionUnitOptions = (props) => (
  (!!props.station)
    ? Object.entries(appInfo.stations[props.station].units)
        .map(x => 
          <option key={'sel-' + x[0]} value={x[0]}>
            {x[0]}
          </option>
        )
    : null
)

export default DepletionUnitOptions